@keyframes appear {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
.quickAppear {
    animation: 0.3s appear forwards;
}
.appear {
    animation: 1s appear forwards;
}