.plusMinusField-container {
    position: relative;
    display: grid;
    grid-template-columns: max-content 35px max-content;
    grid-template-rows: max-content;
    grid-template-areas: "left middle right";
    min-height: 32px;
    width: fit-content;
    background-color: $brand-active-icon-bg-color;
    border-radius: 4px;
    user-select: none;
    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
    .colorFillBg {
        fill: $brand-active-icon-bg-color;
    }
    .colorFill {
        fill: $brand-color2;
    }
    .color2Fill {
        fill: $brand-active-icon-text-color;
    }
    input {
        width: 35px;
        color: #000;
        border: 2px solid $brand-active-icon-bg-color;
        border-radius: 3px;
        text-align: center;
    }
    input:focus {
        outline: none;
    }
}
.plusMinusField-container:hover {
    background-color: $brand-color1;
    box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
}
.plusMinusField-bg {
    position: absolute;
    z-index: 0;
    svg {
        width: 75%;
    }
}
.plusMinusField-count {
    z-index: 1;
    grid-area: middle;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    height: 32px;
    color: $brand-active-icon-text-color;
}
.plusMinusField-plus {
    z-index: 1;
    grid-area: right;
    border: 0px solid;
    width: 25px;
    height: 32px;
    margin: 0 3px;
    cursor: pointer;
    svg {
        width: 100%;
        height: 100%;
    }
}
.plusMinusField-minus {
    z-index: 1;
    grid-area: left;
    border: 0px solid;
    width: 25px;
    height: 32px;
    margin: 0 3px;
    cursor: pointer;
    svg {
        width: 100%;
        height: 100%;
    }
}
.toBasketIcon {
    color: $primary-btn-text-color;
    background-color: $primary-btn-bg-color;
    display: flex;
    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
    padding: 6px 16px;
    min-width: 97px;
    max-width: 97px;
    height: 32px;
    box-sizing: border-box;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    cursor: pointer;
    .backArrow {
        margin-left: 10px;
        height: 20px;
        width: 21px;
        transform: scaleX(-1);
        .colorFill {
            fill: $primary-btn-text-color;
        }
    }
    .cartIcon {
        margin: auto 0px auto 0px;
        height: 15px;
        width: 15px;
        color: $primary-btn-text-color;
        svg {
            height: 15px;
            width: 15px;
        }
    }
}
.toBasketIcon:hover {
    background-color: $secondary-btn-bg-color;
    color: $secondary-btn-text-color;
    box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
}