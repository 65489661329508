// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

/**
 * Set up a decent box model on the root element
 */
html {
  box-sizing: border-box;
}
/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*,
*::before,
*::after {
  box-sizing: inherit;
}
.hidden {
  opacity: 0;
}
a {
  text-decoration: none;
  color: $primary-text-color;
}
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
 
::-webkit-scrollbar-thumb {
  background: $primary-text-color;
  border-radius: 3px;
}

::-webkit-scrollbar-track {
  opacity: 1;
  border-radius: 50px;
}
body {
  -webkit-tap-highlight-color: transparent;
  user-select: none;
  overscroll-behavior-y: contain;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.custom-toast.Toastify__toast--success {
  background: #1b5e20; 
}
.custom-toast.Toastify__toast--warning {
  color: #000;
  background: #ffc107; 
}
.Toastify__toast-container--top-right {
  top: 3em !important;
}
.formErrorContainer {
  padding: 8px;
  border: 1px solid red;
  border-radius: 4px;
  font-size: 0.8em;
  color: red;
  background: #fff;
}
.simplePayButton {
  display: flex;
  justify-content: center;
  @media (min-width: 1024px) {
      justify-content: flex-start;
  }
  form {
    margin: auto;
  }
  button {
      margin-top: 25px;
      color: $primary-btn-text-color;
      background-color: $primary-btn-bg-color;
      border-color: $primary-btn-border-color;
      box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
      padding: 6px 16px;
      font-size: 0.875rem;
      min-width: 64px;
      box-sizing: border-box;
      transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      font-weight: 500;
      line-height: 1.75;
      border-radius: 4px;
      letter-spacing: 0.02857em;
      text-transform: uppercase;
      cursor: pointer;
  }
}
.MuiButton-containedPrimary {
  color: $primary-btn-text-color !important;
  background-color: $primary-btn-bg-color !important;
}
.MuiButton-containedPrimary:hover {
  color: $primary-btn-text-color-hover !important;
  background-color: $primary-btn-bg-color-hover !important;
}
.MuiButton-containedSecondary {
  color: $secondary-btn-text-color !important;
  background-color: $secondary-btn-bg-color !important;
}
.MuiButton-containedSecondary:hover {
  color: $secondary-btn-text-color-hover !important;
  background-color: $secondary-btn-bg-color-hover !important;
}