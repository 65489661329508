/** MAIN **/
.mainContainer {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overscroll-behavior: contain;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
::-webkit-scrollbar {
  display: none;
}
.fullPageCarousel {
  height: 100%;
}
.carousel {
  height: 100%;
}
.carousel-inner {
 height: 100%;
}
.carousel-inner .carousel-item {
  height: 100%;
}
.itemBox {
    border: 3px solid #fff;
    width: 74%;
    height: 85%;
    position: absolute;
    top: 5%;
    left: 13%;
    /* margin: -250px auto 0 -250px; */
    background-color: rgba(0,0,0,.3);
    padding: 10px;
    color: #fff;
    text-align: center;
    overflow: hidden;
}
@media (min-width: 1024px) {
  .itemBox {
    width: 500px;
    height: auto;
    top: 50%;
    left: 50%;
    margin: -250px auto 0 -250px;
    background-color: rgba(0,0,0,.3);
    padding: 50px;
  }
}
.itemBox .itemBoxHeader {
  text-transform: uppercase;
  font-size: 2em;
  font-weight: 700;
  margin-bottom: 20px;
}
/** MENU **/
.menuBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  top: 0px;
  width: 100%;
  background-color: #fff;
  user-select: none;
}
.menuBar .leftContent {
  padding: calc(2px + .4vw);
  padding-left: calc(5px + 2vw);
  display: flex;
}
#menuIconImg {
  width: calc(20px + 1vw);
  margin: auto;
}
.menuBar .empty1 {
  grid-area: empty1
}
.menuBar .menuItems {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.menuBar a {
  text-decoration: none;
}
.menuBar .menuItemContainer {
  margin-right: calc(10px + 1vw);
  display: flex;
}
.menuBar .menuItemContainer.selected {
  border-bottom: 5px solid #627777;
}
.menuBar .menuItem {
  margin: auto;
}
.menuBar .menuItem .menuLink {
  padding: calc(5px + .6vw);
  padding-right: 13px;
  padding-left: 13px;
  color: #627777;
  text-transform: uppercase;
  font-weight: bold;
  font-size: calc(6px + .7vw);
}
.menuBar .empty2 {
  grid-area: empty2
}
.menuBar .rightContent {
  padding-right: 20px;
  display: flex;
  flex-direction: row;
}
.menuBar .rightContent a {
  margin: auto;
}
#menuTrabantImg {
  width: calc(15px + 1vw);
}
#menuInstaImg {
  height: 32px;
  margin: auto;
}
#menuFaceImg {
  height: 32px;
  margin: auto;
}
.menuBar .weatherIcon #menuWeatherNum {
  color: #627777;
}
#menuWeatherImg {
  height: 32px;
  margin: auto;
}

/** CONTENT **/
.contentContainer {
  grid-area: contentContainer;
}

.referenceContent .carItem {
  max-width: 100%;
  height: 500px;
  background-color: rgba(0,0,0,0);
}
.blockContainer {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 100%;
}
.blockItem {
  width: 100%;
}
.blockItem .title {
  border: 1px solid #fff;
  color: #fff;
  text-transform: uppercase;
  font-size: 1.2em;
  font-weight: 700;
  height: 50%;
  width: 80%;
  text-align: center;
  padding: 10px;
  position: relative;
  top: 25%;
  left: 10%;
  background-color: rgba(0,0,0,.3);
  user-select: none;
}
@media (min-width: 1024px) {
  .blockContainer {
    flex-wrap: wrap;
  }
  .blockItem {
    width: 33.3333%;
  }
  .blockItem .title {
    width: 50%;
    height: fit-content;
    top: 40%;
    left: 25%;
  }
}
.blockContainer .blockItem img {
  object-fit: cover;   
}
.contactsContent {
  position: relative;
  height: 100%;
}
.contactsContent .textBox {
  position: relative;
  top: 20%;
  width: 100%;
  padding: 60px 40px;
  overflow: hidden;
  color: #fff;
  background-color: rgba(0,0,0,0.30);
}
@media (min-width: 1024px) {
  .contactsContent .textBox {
    position: relative;
    top: 11vw;
    display: block;
    margin: auto;
    width: 500px;
    border: #fff 1px solid;
    padding: 60px 40px;
    overflow: hidden;
    color: #fff;
    background-color: rgba(0,0,0,0.10);
  }
}
.contactsContent .textBox .header {
  text-transform: uppercase;
  font-size: 2.5em;
  font-weight: bold;
  letter-spacing: 3px;
  margin-bottom: 20px;
}
.contactsContent .textBox .body {
  font-size: 1.3em;
}
.contactsContent .textBox .body .icon {
  margin-right: 5px;
}
.contactsContent .textBox .body .listItem {
  margin-bottom: 8px;
}

/** SERVICES **/
.servicesContent {
  height: 100%;
  overflow: hidden;
}
.servicesContent .container-fluid {
  height: 100%;
  padding: 0;
}
.servicesContent .container-fluid .row {
  height: 100%;
}
.servicesContent .row > div {
  padding: 0;
}
.servicesContent .serviceItem {
  height: 100%;
  background-size: cover;
}
.servicesContent .serviceItem .title{
  border: 1px #fff solid;
  color: #fff;
  text-transform: uppercase;
  font-size: 1.2em;
  font-weight: bold;
  width: 50%;
  text-align: center;
  padding: 10px;
  position: absolute;
  top: 40%;
  left: 25%;
  background-color: rgba(0,0,0,0.30);
  user-select: none;
}
.servicesContent #wedding {
  background-image: url('/assets/img/brand/szolgaltatasok_eskuvo.png');
}
.servicesContent #henparty {
  background-image: url('/assets/img/brand/szolgaltatasok_lanybucsu.png');
}
.servicesContent #teambuilding {
  background-image: url('/assets/img/brand/szolgaltatasok_csapatepites.png');
}
.servicesContent #workshop {
  background-image: url('/assets/img/brand/szolgaltatasok_workshop.png');
}
.servicesContent #concert {
  background-image: url('/assets/img/brand/szolgaltatasok_koncert.png');
}
.servicesContent #sport {
  background-image: url('/assets/img/brand/szolgaltatasok_sport.png');
}
