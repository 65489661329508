.basketItemContainer {
    display: grid;
    grid-template-rows: 50px;
    grid-template-columns: 50px max-content 1fr 75px 75px 45px;
    grid-template-areas: "itemimg countbutton itemname itemprice itemtotalprice removeitem";
    gap: 8px;
    font-size: 0.9em;
    border-radius: 4px;
    padding: 5px;
    .itemImg {
        overflow: hidden;
        border-radius: 4px;
    }
    img {
        grid-area: itemimg;
        height: 100%;
    }
    .itemName {
        overflow: hidden;
        font-size: 0.7em;
        margin: 0 8px;
        .itemName {
            a {
                color: #000;
            }        
        }
        @media (min-width: 768px) {
            font-size: 1em;
        }
    }
    .itemPrice {
        grid-area: itemprice;
        display: inline-flex;
        .currency {
            margin-left: 4px;
        }
        justify-content: flex-end;
    }
    @media all and (orientation:portrait) {
        grid-template-columns: max-content 1fr 65px;
        grid-template-areas: "countbutton itemname itemtotalprice";
        .itemImg {
            display: none;
        }
        img {
            display: none;
        }
        .removeItem {
            display: none;
        }
        .itemPrice {
            display: none;
        }
    }
    .itemName {
        cursor: pointer;
        grid-area: itemname;
        .itemName {
            a {
                color: #000;
            }        
        }
    }
    .itemName:hover {
        text-decoration: underline;
    }
    .itemCount {
        grid-area: itemcount;
        display: none;
    }
    .itemTotalPrice {
        grid-area: itemtotalprice;
        display: inline-flex;
        .currency {
            margin-left: 4px;
        }
        justify-content: flex-end;
    }
    .removeItem {
        grid-area: removeitem;
        cursor: pointer;
        margin: auto;
    }
    .countButton {
        grid-area: countbutton;
        display: block;
        margin: auto;
    }
    .removeItem {
        svg {
            height: 45px;
            width: 25px;
            .colorFillBg {
                fill:#c51162;
            }
            .color2Fill {
                fill: #fff;
            }
        }
    }
    .removeItem:hover {
        .colorFillBg {
            fill:#fff;
        }
        .color2Fill {
            fill: #f50057;
        }
    }
}
.basketItemContainer.minimized {
    display: grid;
    grid-template-rows: 45px;
    grid-template-columns: 45px max-content 1fr 25px 70px;
    grid-template-areas: "itemimg countbutton itemname removeitem itemtotalprice";
    gap: 4px;
    .itemName {
        a {
            color: #000;
        }        
    }
    @media all and (orientation:portrait) {
        grid-template-columns: max-content 1fr max-content;
        grid-template-areas: "countbutton itemname itemtotalprice";
        padding: 0px;
        .itemImg {
            display: none;
        }
        img {
            display: none;
        }
        .removeItem {
            display: none;
        }
        .itemName {
            font-size: 0.8em;
        }
    }
    .itemPrice {
        display: none;
    }
    .countButton {
        display: block;
    }
    .itemCount {
        display: none;
    }
}
.basketItemContainer:hover {
    background-color: #3f51b533;
}