.categoriesContainer {
    grid-area: categories;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    .searchBarContainer {
        background: #423930;
        .react-autosuggest__container {
            color: rgba(0, 0, 0, 0.87);
            cursor: text;
            display: inline-flex;
            position: relative;
            font-size: 1rem;
            box-sizing: border-box;
            align-items: center;
            font-family: "Roboto", "Helvetica", "Arial", sans-serif;
            font-weight: 400;
            line-height: 1.1876em;
            letter-spacing: 0.00938em;
            padding-left: 15px;
            ::-webkit-scrollbar-thumb {
                background: #fff;
                border-radius: 3px;
            }
            .react-autosuggest__suggestions-container {
                position: absolute;
                top: 48px;
                left: 0;
                background-color: #fff;
                width: max-content;
                z-index: 200;
                ul {
                    list-style-type: none;
                    padding: 0;
                    margin: 5px;
                    
                }
                .react-autosuggest__suggestion {
                    padding: 5px 15px;
                }                
                .react-autosuggest__suggestion:hover {
                    background: rgba(66, 57, 48, 0.8);
                    color: #fff;
                    cursor: pointer;
                }
                /*.react-autosuggest__suggestion--highlighted {
                
                }*/
            }
            input {
                font: inherit;
                color: currentColor;
                width: 100%;
                border: 0;
                height: 1.1876em;
                margin: 0;
                display: block;
                padding: 6px 0 7px;
                min-width: 0;
                background: none;
                box-sizing: content-box;
                animation-name: mui-auto-fill-cancel;
                -webkit-tap-highlight-color: transparent;
                outline: none;
            }
        }
        form {
            background-color: #fff;
            display: grid;
            grid-template-columns: 1fr max-content;
            border-radius: 0;
            margin-top: 8px;
            margin-bottom: 8px;
            .MuiInputBase-root {
                padding-left: 15px;
            }
        }
    }
    .categoryItems {
        grid-template-columns: 1fr max-content;
        background-color: #fff;
        padding: 8px;
        border-top: 1px solid rgba(66, 57, 48, 0.3);
        color: #423930;
        min-width: 180px;
        display: grid;
        @media (min-width: 768px) {
            
        }
        p {
            font-size: 14px;
            margin: 5px 15px 0px 0px;
        }
        svg {
            opacity: 0.3;
        }
    }
    .categoryItems:last-child {
        border-bottom: 1px solid rgba(66, 57, 48, 0.3);
    }
    .categoryItems:hover {
        color: #fff;
        text-decoration: underline;
        cursor: pointer;
        background-color: rgba(66, 57, 48, 0.8);
        svg {
            opacity: 1;
        }
    }
    .categorySelect {
        label {
            color: #fff;
        }
    }
}
.categoriesContainer.minimized {
    margin: 0;
    flex-direction: row;
    @media (min-width: 768px) {
        margin: 20px 0 auto 20px;
    }
    @media (min-width: 1024px) {
        margin: 20px 0 auto 60px;
    }
    @media (min-width: 1440px) {
        margin: 20px 0 auto auto;
    }
    .searchBarContainer {
        width: 100%;
        margin-right: 0;
        @media (min-width: 768px) {
            width: 175px;
            margin-right: 15px;
        }
        @media (min-width: 1024px) {
            width: 250px;
        }
    }
    .categorySelect {
        margin: auto;
    }
}
.categorySelect {
    width: 100%;
    padding-left: 15px;
    padding-bottom: 8px;
    @media (min-width: 768px) {
        width: auto;
        padding-left: 0;
        padding-bottom: 0;
    }
    .MuiFormControl-root {
        min-width: 150px;
        .MuiInputBase-root {
            .MuiSelect-root {
                color: #fff;
                
            }
        }
    }
}