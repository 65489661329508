.itemListItemContainer {
    position: relative;
    .outOfStock {
        position: absolute;
        top: 0;
        left: 0;
        width: calc(100% - 16px);
        height: calc(100% - 16px);
        z-index: 1;
        display: flex;
        justify-content: center;
        padding: 15px;
        background: rgba(0,0,0, 0.6);
        color: $primary-text-color;
        margin: 8px;
        .message {
            text-align: center;
            width: fit-content;
            height: fit-content;
            margin: 25% auto;
        }
    }
    .itemCard {
        margin: 8px 0 8px 0;
        min-height: 104px;
        max-height: 270px;
        display: flex;
        flex-direction: column;
        background-color: #fff;
        border: 1px solid #b4b4b4;
        color: #1168b9;
        @media (min-width: 1024px) {
            margin: 8px 8px 8px 0;
            text-align: center;
            max-height: 500px;
        }
        a {
            color: #1168b9;
        }
        .itemInfo {
            display: flex;
            padding: 15px 8px 5px 15px;
            height: 120px;
            /*grid-template-areas: 
                "cardimg cardcontent";
            grid-template-columns: 100px 1fr;*/
            //gap: 5px;
            @media (min-width: 1024px) {
                flex-direction: column;
                height: fit-content;
                /*grid-template-areas: 
                "cardimg" 
                "cardcontent";
                grid-template-columns: 1fr;
                grid-template-rows: 105px 1fr;*/
            }
            .cardContent {
                grid-area: cardcontent;
                min-height: 100px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                padding: 0 10px;
                @media (min-width: 1024px) {
                    margin: auto;
                    //min-height: 200px;
                }
                .itemName {                    
                    overflow: hidden;
                    font-weight: 500;
                    margin-bottom: 5px;
                }
                .itemDesc { 
                    overflow: hidden;
                    color: rgba(0,0,0, 0.6);
                    margin-bottom: 5px;
                }
                .itemPrice {
                    color: #000;
                    font-weight: 500;
                }
            }
            .cardImg {
                grid-area: cardimg;
                display: flex;
                width: 100px;
                @media (min-width: 1024px) {
                    margin: auto;
                }
                img {
                    width: 100px;
                    margin: 0 auto auto auto;
                    @media (min-width: 1024px) {
                        height: 100px;
                        width: auto;
                        margin: 0 auto 8px auto;
                    }
                    @media (min-width: 1440px) {
                        margin: 0 auto 12px auto;
                    } 
                }  
            }
        }
        .cardActions {
            margin: 15px 0 10px 0;
            display: flex;
            justify-content: center;
        }
        .message {
            display: flex;
            justify-content: center;
            color: $brand-color1;
            min-height: 40px;
            font-size: 14px;
            span {
                margin: auto;
            }
        }
    }
}
.itemListItemContainer.selected {
    .itemCard {
        transition: 0.4s;
        border: 1px solid $brand-color1;
    }
}