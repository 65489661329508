// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// Base text, background colors
// -----------------------------------------------------------------------------
$primary-text-color: #627777;
$primary-background-color: #fff;
$secondary-text-color: #627777; 
$secondary-background-color: #fff;
// -----------------------------------------------------------------------------
// Primary button colors
// -----------------------------------------------------------------------------
$primary-btn-text-color: #fff;
$primary-btn-bg-color: #627777;
$primary-btn-border-color: #627777;
$primary-btn-text-color-hover: #fff;
$primary-btn-bg-color-hover: #495858;
$primary-btn-border-color-hover: #495858;
// -----------------------------------------------------------------------------
// Secondary button colors
// -----------------------------------------------------------------------------
$secondary-btn-text-color: #fff;
$secondary-btn-bg-color: #4d8a8a;
$secondary-btn-border-color: #4d8a8a;
$secondary-btn-text-color-hover: #fff;
$secondary-btn-bg-color-hover: #50aaaa;
$secondary-btn-border-color-hover: #50aaaa;
// -----------------------------------------------------------------------------
// Active icon colors
// -----------------------------------------------------------------------------
$active-icon-text-color: #627777;
$active-icon-bg-color: #fff;
$active-icon-border-color: #fff;
$active-icon-badge-text-color: #fff;
$active-icon-badge-bg-color: rgb(0, 0, 0);
// -----------------------------------------------------------------------------
// Passive icon colors
// -----------------------------------------------------------------------------
$passive-icon-text-color: #000000;
$passive-icon-bg-color: #302e2e;
$passive-icon-border-color: #302e2e;
$passive-icon-badge-text-color: #636060;
$passive-icon-badge-bg-color: #302e2e;
// -----------------------------------------------------------------------------
// Brand colors
// -----------------------------------------------------------------------------
$brand-color1: #627777;
$brand-color2:#627777;
// -----------------------------------------------------------------------------
// Brand themed active icon colors
// -----------------------------------------------------------------------------
$brand-active-icon-text-color: #627777;
$brand-active-icon-bg-color: #fff;
$brand-active-icon-border-color: #fff;
$brand-active-icon-badge-text-color: #fff;
$brand-active-icon-badge-bg-color: #000;
// -----------------------------------------------------------------------------
// Brand themed passive icon colors
// -----------------------------------------------------------------------------
$brand-passive-icon-text-color: #fff;
$brand-passive-icon-bg-color: #000;
$brand-passive-icon-border-color: #fff;
$brand-passive-icon-badge-text-color: #fff;
$brand-passive-icon-badge-bg-color: #000;
/// Relative or absolute URL where all assets are served from
/// @type String
/// @example scss - When using a CDN
///   $base-url: 'http://cdn.example.com/assets/';
$base-url: './assets/' !default;
