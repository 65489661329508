.languageIconContainer {
    height: max-content;
    width: max-content;
    display: none;
    position: absolute;
    color: $active-icon-text-color;
    cursor: pointer;
}
.languagePopover {
    padding: 10px;
    width: 52px;
    img {
        cursor: pointer;
    }
    img:first-child {
        margin-bottom: 5px;
    }
}
