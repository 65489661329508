.emptyBasketMessage {
    padding: 10px;
}
.basket {
    display: grid;
    grid-template-areas: 
    "actions"
    "header"
    "basketitems"
    "sumtotalprice";
    grid-template-rows: max-content max-content 1fr max-content;
    .basketCard {
        box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
        width: 95%;
        @media (min-width: 768px) {
            width: 90%;
        }
        margin: 40px auto 15px auto;
        padding: 8px;
        background: #fff;
        color: #000;
        .deliveryInfo {
            text-align: center;
            margin: 25px;
        }
        a {
            color: #000;
        }     
    }
    .header {
        grid-area: header;
        margin: auto;
        font-size: 0.8em;
        font-weight: bold;
        display: grid;
        gap: 8px;
        grid-template-areas: ". itemquantity itemname itemprice itemsumprice .";
        grid-template-columns: 60px 100px 1fr 75px 75px 45px;
        .itemName {
            grid-area: itemname;
        }
        .itemQuantity {
            grid-area: itemquantity;
        }
        .itemPrice {
            grid-area: itemprice;
        }
        .itemSumPrice {
            grid-area: itemsumprice;
            text-align: right;
        }
    }
    @media all and (orientation:portrait) {
        .header {
            grid-template-areas: ". itemquantity itemname itemsumprice";
            grid-template-columns: 10px max-content 1fr max-content;
            .itemPrice {
                display: none;
            }
        }
    }
    .sumTotalPrice {
        display: grid;
        gap: 4px;
        grid-template-columns: 1fr max-content max-content;
        font-weight: bold;
        grid-area: sumtotalprice;
        margin: 20px auto 5px auto;
        width: 80%;
    }
    .basketItems {
        grid-area: basketitems;
        font-size: 1.1em;
        box-shadow: 0 1px rgba(32, 33, 37, 0.12);
    }
    .actions {
        grid-area: actions;
        display: flex;
        overflow-x: auto;
        overflow-y: hidden;
        justify-content: center;
        height: auto;
        flex-wrap: wrap;
        .finalizeOrder {
            display: none;
        }
        button {
            margin: 5px 5px 5px 0;
        }
    }
}
.basket.minimized {
    padding: 10px;
    display: grid;
    grid-template-areas: 
    "basketitems"
    "sumtotalprice"
    "actions";
    grid-template-rows: 1fr max-content max-content;
    gap: 5px;
    @media all and (orientation:portrait) {
        padding: 5px;
    }
    .basketCard {
        box-shadow: none;
        width: auto;
        margin: 0;
        @media all and (orientation:portrait) {
            padding: 0px;
        }
        .deliveryInfo {
            display: none;
        }
    }
    .header {
       display: none;
    }
    .basketItems {
        box-shadow: 0 1px rgba(32, 33, 37, 0.12);
        width: 100%;
    }
    .sumTotalPrice {
        box-shadow: 0 1px rgba(32, 33, 37, 0.12);
        padding: 5px 0;
    }
    .actions {
        display: contents;
        box-shadow: none;
        padding: 0;
        margin-bottom: 0;
        .continueShopping {
            display: none;
        }
        .finalizeOrder {
            display: block;
        }
        button {
            width: auto;
            margin: auto;
        }
    }
}